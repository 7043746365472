@font-face {
  font-family: 'fontello';
  src: url('fontello.eot?_=1591507580');
  src:
    url('fontello.eot?_=1591507580#iefix') format('embedded-opentype'),
    url('fontello.woff2?_=1591507580') format('woff2'),
    url('fontello.woff?_=1591507580') format('woff'),
    url('fontello.ttf?_=1591507580') format('truetype'),
    url('fontello.svg?_=1591507580#fontello') format('svg');
  font-weight: normal;
  font-style: normal
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('fontello.svg?_=1591507580#fontello') format('svg');
  }
}
*/

[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  /* line-height: 1em; */

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-2x::before {
  font-size: 2rem
}

.icon-archive::before { content: '\e800' } /* '' */
.icon-sharex::before { content: '\e801' } /* '' */
.icon-picture::before { content: '\e802' } /* '' */
.icon-th-list::before { content: '\e803' } /* '' */
.icon-trash::before { content: '\e804' } /* '' */
.icon-cancel::before { content: '\e805' } /* '' */
.icon-arrows-cw::before { content: '\e806' } /* '' */
.icon-plus::before { content: '\e807' } /* '' */
.icon-clipboard::before { content: '\e808' } /* '' */
.icon-login::before { content: '\e809' } /* '' */
.icon-home::before { content: '\e80a' } /* '' */
.icon-gauge::before { content: '\e80b' } /* '' */
.icon-video:before { content: '\e80c'; } /* '' */
.icon-help-circled::before { content: '\e80d' } /* '' */
.icon-github-circled::before { content: '\e80e' } /* '' */
.icon-pencil::before { content: '\e80f' } /* '' */
.icon-terminal::before { content: '\e810' } /* '' */
.icon-hammer::before { content: '\e811' } /* '' */
.icon-block::before { content: '\e812' } /* '' */
.icon-link::before { content: '\e813' } /* '' */
.icon-cog-alt::before { content: '\e814' } /* '' */
.icon-floppy::before { content: '\e815' } /* '' */
.icon-user-plus::before { content: '\e816' } /* '' */
.icon-privatebin::before { content: '\e817' } /* '' */
.icon-upload-cloud::before { content: '\e819' } /* '' */
.icon-th-large::before { content: '\e81a' } /* '' */
.icon-download::before { content: '\e81b' } /* '' */
.icon-gatsby::before { content: '\e81c' } /* '' */
.icon-filter::before { content: '\f0b0' } /* '' */
.icon-docs::before { content: '\f0c5' } /* '' */
.icon-exchange:before { content: '\f0ec'; } /* '' */
.icon-doc-inv::before { content: '\f15b' } /* '' */
.icon-paper-plane::before { content: '\f1d8' } /* '' */
.icon-chrome::before { content: '\f268' } /* '' */
.icon-firefox::before { content: '\f269' } /* '' */
